import { z } from 'zod'
import { Role, SubscriptionPlan } from '@prisma/client'
import { BaseEntitySchema } from '~/schemas/base/base-entity'
import {
    requiredCurrencyCode,
    requiredString,
    requiredUic,
    requiredUuid,
} from '~/schemas/util'
import { CreateAddressSchema, AddressSchema } from '~/schemas/address'
import { FileSchema } from '~/schemas/files'
import { TaxSchema } from '~/schemas/tax'
import { UnitSchema } from '~/schemas/unit'
import { SupportedCurrencySchema } from '~/schemas/currency'

export const CompanySettingsSchema = z.object({
    invoicePrefix: z
        .string()
        .min(0)
        .max(9)
        .regex(/^[0-9]{0,9}$/),
    invoiceNumber: z.number().min(0).max(999_999_999),
    proformaInvoicePrefix: z.string().min(0).max(9),
    proformaInvoiceNumber: z.number().min(0).max(999_999_999),
    purchasesVatExportAccountId: requiredUuid().nullable(),
    salesVatExportAccountId: requiredUuid().nullable(),
})

export const UpdateCompanySettingsSchema = CompanySettingsSchema.partial()

export const CreateCompanySchema = z
    .object({
        id: z.string().uuid().optional(),
        name_bg: requiredString(),
        name_en: z.string().default(''),
        uic: requiredUic(),
        isVatRegistered: z.boolean(),
        vat: z.string().default(''),
        baseCurrencyCode: requiredCurrencyCode(),
        address: CreateAddressSchema,
    })
    .refine(({ isVatRegistered, vat }) => !isVatRegistered || vat)

export const UpdateCompanySchema = z
    .object({
        name_bg: requiredString(),
        name_en: z.string().default(''),
        uic: requiredUic(),
        isVatRegistered: z.boolean(),
        vat: z.string().default(''),
        address: CreateAddressSchema,
    })
    .partial()
    .refine(({ isVatRegistered, vat }) => !isVatRegistered || vat)

export const CompanySchema = BaseEntitySchema.extend({
    name_bg: requiredString(),
    name_en: z.string(),
    slug: requiredString(),
    uic: requiredUic(),
    isVatRegistered: z.boolean(),
    vat: z.string(),
    role: z.nativeEnum(Role),
    selected: z.boolean(),
    logo: FileSchema.nullable(),
    baseCurrency: SupportedCurrencySchema,
    baseCurrencyCode: requiredCurrencyCode(),
    taxes: z.array(TaxSchema),
    units: z.array(UnitSchema),
    address: AddressSchema,
    stripeCustomerId: z.string().nullable(),
    stripeSubscriptionId: z.string().nullable(),
    stripePriceId: z.string().nullable(),
    subscriptionPlan: z.nativeEnum(SubscriptionPlan),
})

export const BasicCompanySchema = CompanySchema.pick({
    id: true,
    name_bg: true,
    name_en: true,
    slug: true,
    logo: true,
})

export type CreateCompanyRequest = z.infer<typeof CreateCompanySchema>
export type UpdateCompanyRequest = z.infer<typeof UpdateCompanySchema>

export type UpdateCompanySettingsRequest = z.infer<
    typeof UpdateCompanySettingsSchema
>

export type CompanySettingsResponse = z.infer<typeof CompanySettingsSchema>

export type BasicCompanyResponse = z.infer<typeof BasicCompanySchema>
export type CompanyResponse = z.infer<typeof CompanySchema>
