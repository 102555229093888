import { z } from 'zod'
import { BaseEntitySchema } from '~/schemas/base/base-entity'
import { requiredString } from '~/schemas/util'
import { FolderSchema } from './folder'

export const FileSchema = BaseEntitySchema.extend({
    name: requiredString(),
    mimetype: requiredString(),
    size: z.number(),
    url: requiredString(),
    folder: FolderSchema.optional(),
    folderId: z.string().uuid().nullable(),
    uploadedBy: requiredString().nullable(),
})

export const RenameFileSchema = z.object({
    name: requiredString(),
})

export type FileResponse = z.infer<typeof FileSchema>
export type RenameFileRequest = z.infer<typeof RenameFileSchema>
