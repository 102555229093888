import { z } from 'zod'
import { requiredString } from '../util'
import { BaseEntitySchema } from '../base/base-entity'
import { FolderType } from '@prisma/client'

export const CreateFolderSchema = z.object({
    name: requiredString(),
})

export const FolderSchema = BaseEntitySchema.extend({
    name: requiredString(),
    type: z.nativeEnum(FolderType),
    fileCount: z.number(),
})

export const RenameFolderSchema = z.object({
    name: requiredString(),
})

export type CreateFolderRequest = z.infer<typeof CreateFolderSchema>
export type RenameFolderRequest = z.infer<typeof RenameFolderSchema>
export type FolderResponse = z.infer<typeof FolderSchema>
export type FoldersResponse = {
    folders: FolderResponse[]
    totalSize: number
}
