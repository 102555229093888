import { z } from 'zod'
import { FileSchema, OrderDirection, type PaginationQuery } from '~/schemas'
import {
    createFiltersSchema,
    createOrderSchema,
    createPaginatedResponseSchema,
} from '~/schemas/util'

export const FileOrderSchema = createOrderSchema(
    z.enum(['name', 'size', 'createdAt', 'uploadedBy']),
    'createdAt',
    OrderDirection.desc,
)

export const FileFiltersSchema = createFiltersSchema(
    z.object({
        folderId: z.string().uuid().optional(),
        search: z.string().optional(),
    }),
)

export const PaginatedFilesSchema = createPaginatedResponseSchema(
    FileSchema,
    FileOrderSchema,
    FileFiltersSchema,
)

export type FileOrderQuery = z.infer<typeof FileOrderSchema>
export type FileFiltersQuery = z.infer<typeof FileFiltersSchema>
export type PaginatedFilesRequest = {
    pagination: PaginationQuery
    order: FileOrderQuery
    filters: FileFiltersQuery
}
export type PaginatedFilesResponse = z.infer<typeof PaginatedFilesSchema>
