import { SubscriptionPlan } from '@prisma/client'

export enum SubscriptionSubject {
    EXPORT_PDF_INVOICES = 'export_pdf_invoices',
    SEND_PDF_VIA_EMAIL = 'send_pdf_via_email',
    BG_EN_DOC_LANGS = 'bg_en_doc_langs',
    CURRENCIES = 'currencies',
    DATA_EXPORT = 'data_export',
    REVENUES = 'revenues',
    EXPENSES = 'expenses',
    CONTACTS = 'contacts',
    ITEMS = 'items',
    LOGO = 'logo',
    EXPENSE_FILE_ATTACHMENTS = 'expense_file_attachments',
    FILES = 'files',
    OCR_AI = 'ocr_ai',
    // ONLINE_SUPPORT = 'online_support',
    TRADING_OBJECTS = 'trading_objects',
    CUSTOM_ROLES = 'custom_roles',
}

export const QUANTITY_UNLIMITED = 'quantity_unlimited'

type QuantifiableFeature = {
    isMonthly: boolean
    quantity: number | typeof QUANTITY_UNLIMITED
}

type ToggleableFeature = {
    isEnabled: boolean
}

export type ResourceQuantityLimit = {
    left: number | typeof QUANTITY_UNLIMITED
    limit: number | typeof QUANTITY_UNLIMITED
    isMonthly: boolean
}

export type PlanFeatures = {
    [SubscriptionSubject.EXPORT_PDF_INVOICES]: ToggleableFeature
    [SubscriptionSubject.SEND_PDF_VIA_EMAIL]: ToggleableFeature
    [SubscriptionSubject.BG_EN_DOC_LANGS]: ToggleableFeature
    [SubscriptionSubject.CURRENCIES]: ToggleableFeature
    [SubscriptionSubject.DATA_EXPORT]: ToggleableFeature
    [SubscriptionSubject.REVENUES]: QuantifiableFeature
    [SubscriptionSubject.EXPENSES]: QuantifiableFeature
    [SubscriptionSubject.CONTACTS]: QuantifiableFeature
    [SubscriptionSubject.ITEMS]: QuantifiableFeature
    [SubscriptionSubject.LOGO]: ToggleableFeature
    [SubscriptionSubject.EXPENSE_FILE_ATTACHMENTS]: ToggleableFeature
    [SubscriptionSubject.FILES]: QuantifiableFeature
    [SubscriptionSubject.OCR_AI]: ToggleableFeature
    // [SubscriptionSubject.ONLINE_SUPPORT]: ToggleableFeature
    [SubscriptionSubject.TRADING_OBJECTS]: ToggleableFeature
    [SubscriptionSubject.CUSTOM_ROLES]: ToggleableFeature
}

type SubscriptionConstraint = Record<SubscriptionPlan, PlanFeatures>

export const SUBSCRIPTION_CONSTRAINTS: SubscriptionConstraint = {
    [SubscriptionPlan.FREE]: {
        export_pdf_invoices: {
            isEnabled: true,
        },
        send_pdf_via_email: {
            isEnabled: false,
        },
        bg_en_doc_langs: {
            isEnabled: true,
        },
        currencies: {
            isEnabled: true,
        },
        data_export: {
            isEnabled: true,
        },
        revenues: {
            isMonthly: true,
            quantity: 10,
        },
        expenses: {
            isMonthly: true,
            quantity: 10,
        },
        contacts: {
            isMonthly: false,
            quantity: 10,
        },
        items: {
            isMonthly: false,
            quantity: 10,
        },
        logo: {
            isEnabled: false,
        },
        expense_file_attachments: {
            isEnabled: false,
        },
        files: {
            isMonthly: false,
            quantity: 0,
        },
        // online_support: {
        //     isEnabled: false,
        // },
        ocr_ai: {
            isEnabled: false,
        },
        trading_objects: {
            isEnabled: false,
        },
        custom_roles: {
            isEnabled: false,
        },
    },
    [SubscriptionPlan.STANDARD]: {
        export_pdf_invoices: {
            isEnabled: true,
        },
        bg_en_doc_langs: {
            isEnabled: true,
        },
        currencies: {
            isEnabled: true,
        },
        data_export: {
            isEnabled: true,
        },
        revenues: {
            isMonthly: true,
            quantity: QUANTITY_UNLIMITED,
        },
        expenses: {
            isMonthly: true,
            quantity: QUANTITY_UNLIMITED,
        },
        contacts: {
            isMonthly: false,
            quantity: QUANTITY_UNLIMITED,
        },
        items: {
            isMonthly: false,
            quantity: QUANTITY_UNLIMITED,
        },
        send_pdf_via_email: {
            isEnabled: true,
        },
        logo: {
            isEnabled: true,
        },
        expense_file_attachments: {
            isEnabled: true,
        },
        files: {
            isMonthly: false,
            quantity: 100 * 1000 * 1000, // 100 MB
        },
        // online_support: {
        //     isEnabled: true,
        // },
        ocr_ai: {
            isEnabled: false,
        },
        trading_objects: {
            isEnabled: false,
        },
        custom_roles: {
            isEnabled: false,
        },
    },
    [SubscriptionPlan.PRO]: {
        export_pdf_invoices: {
            isEnabled: true,
        },
        bg_en_doc_langs: {
            isEnabled: true,
        },
        currencies: {
            isEnabled: true,
        },
        data_export: {
            isEnabled: true,
        },
        revenues: {
            isMonthly: true,
            quantity: QUANTITY_UNLIMITED,
        },
        expenses: {
            isMonthly: true,
            quantity: QUANTITY_UNLIMITED,
        },
        contacts: {
            isMonthly: false,
            quantity: QUANTITY_UNLIMITED,
        },
        items: {
            isMonthly: false,
            quantity: QUANTITY_UNLIMITED,
        },
        send_pdf_via_email: {
            isEnabled: true,
        },
        logo: {
            isEnabled: true,
        },
        expense_file_attachments: {
            isEnabled: true,
        },
        files: {
            isMonthly: false,
            quantity: 1000 * 1000 * 1000, // 1 GB
        },
        // online_support: {
        //     isEnabled: true,
        // },
        ocr_ai: {
            isEnabled: true,
        },
        trading_objects: {
            isEnabled: true,
        },
        custom_roles: {
            isEnabled: true,
        },
    },
}

export function getSubscriptionConstraints<K extends SubscriptionSubject>(
    subscriptionPlan: SubscriptionPlan,
    type: K,
): PlanFeatures[K] {
    return SUBSCRIPTION_CONSTRAINTS[subscriptionPlan][type]
}
